import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import styles from "./Home.module.scss";
import TextInput from "src/components/TextInput/TextInput";
import { useQuery } from "src/hooks/useQuery";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { CarsActions, CarsType } from "src/store/cars";
import { DateTime } from "luxon";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { get } from "lodash";
import Button from "src/components/Button/Button";
import Loading from "src/components/Loading/Loading";
import { PriceDistribution } from "src/internal/PriceDistribution";

interface Props {}

const Home: FC<Props> = memo(() => {
  const ref = useRef<HTMLDivElement>(null);
  const additionalRef = useRef<HTMLDivElement>(null);
  const instanceRef = useRef<PriceDistribution[]>([]);
  const query = useQuery();
  const history = useHistory();
  const date = query.get("date");
  const carId = query.get("car_id");
  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState(date || "");
  const [carIdValue, setCarIdValue] = useState(carId || "");
  const { priceDistributionData, isLoading } = useTypedSelector(
    ({ cars: { priceDistributionData }, loading: { asyncMap } }) => {
      return {
        priceDistributionData,
        isLoading: get(asyncMap, CarsType.GET_PRICE_DISTRIBUTION_DATA) > 0,
      };
    }
  );

  useEffect(() => {
    if (!carId && date === null) {
      query.set("date", "2020-05-01");
      history.push(`?${query.toString()}`);
      return;
    }
  }, [carId, date, history]);

  useEffect(() => {
    if (carId === null && date === null) {
      return;
    }

    const params: { date?: string; car_id?: string } = {};

    if (carId) {
      params["car_id"] = carId;
      setCarIdValue(carId);
    }

    if (date) {
      params["date"] = date;
      setDateValue(date);
    }

    dispatch(CarsActions.getPriceDistributionData(params));
  }, [carId, date, dispatch]);

  const handleDateSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!DateTime.fromFormat(dateValue, "yyyy-MM-dd").isValid) {
        return;
      }

      query.delete("car_id");
      query.set("date", dateValue);
      history.push(`?${query.toString()}`);
    },
    [query, dateValue]
  );

  const handleCarIdSubmit = useCallback(
    (e) => {
      e.preventDefault();

      query.delete("date");
      query.set("car_id", carIdValue);
      history.push(`?${query.toString()}`);
      setDateValue("");
    },
    [query, carIdValue]
  );

  useEffect(() => {
    if (!ref.current || isLoading) {
      return;
    }

    for (let i = 0; i < priceDistributionData.length; i++) {
      const { a, car } = priceDistributionData[i];
      const p = document.createElement("p");
      const id = get(car, "id");
      const gradeName = get(car, "grade_name");
      const year = get(car, "year");

      p.className = styles.description;
      p.innerHTML = `<b>ID: ${id}</b><br /><span>연식: ${year}</span><br /><span>등급이름: ${gradeName}</span>`;

      const parent = document.createElement("div");
      const parent2 = document.createElement("div");
      parent.className = styles.instance;
      parent2.className = styles.instance2;
      parent.appendChild(p);
      parent.appendChild(parent2);

      ref.current.appendChild(parent);

      const data = [a];
      for (let i = 0; i < data.length; i++) {
        const child = document.createElement("div");
        const header = document.createElement('p');
        header.className = styles.description;
        child.appendChild(header);
        parent2.appendChild(child)

        instanceRef.current.push(
          new PriceDistribution(
            child,
            data[i],
            i,
            (pd: PriceDistribution) => {
              if (typeof pd.xType === "number") {
                header.innerHTML += `x type: ${String.fromCharCode(
                  pd.xType + 65
                )}`;
              }

              header.innerHTML += `<br />type: ${pd.type}`;
              header.innerHTML += `<br />group: ${pd.group}`;
              header.innerHTML += `<br />data min: ${pd.dataMin}<br />data max: ${pd.dataMax}`;
              header.innerHTML += `<br />mileage min: ${pd.mileageMin}<br />mileage max: ${pd.mileageMax}`;
            }
          )
        );
      }
    }

    return () => {
      for (let i = 0; i < instanceRef.current.length; i++) {
        instanceRef.current[i].destroy();
      }

      instanceRef.current = [];

      if (!ref.current) {
        return;
      }

      ref.current.innerHTML = "";
    };
  }, [priceDistributionData, isLoading]);

  return (
    <div className={styles.home}>
      <form className={styles.form} onSubmit={handleCarIdSubmit}>
        <div className={styles.inner}>
          <TextInput
            placeholder="차량 아이디"
            className={styles.input}
            value={carIdValue}
            onChange={(e) => {
              setCarIdValue(e.currentTarget.value.replace(/\D/g, ""));
            }}
          />
          <Button className={styles.button}>입력</Button>
        </div>
      </form>
      <form className={styles.form} onSubmit={handleDateSubmit}>
        <div className={styles.inner}>
          <TextInput
            placeholder="날짜"
            className={styles.input}
            value={dateValue}
            onChange={(e) => {
              setDateValue(e.currentTarget.value);
            }}
          />
          <Button className={styles.button}>입력</Button>
        </div>
      </form>
      <div ref={additionalRef} />
      <div>
        {isLoading && <Loading />}
        <div ref={ref} className={styles.charts} />
      </div>
    </div>
  );
});

export default Home;
