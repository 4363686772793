import {
  Circle,
  CircleOptions,
  Line,
  LineOptions,
  Text,
  TextOptions,
  Rectangle,
  RectangleOptions,
  BezierCurveLine,
  BezierCurveLineOptions,
} from "canvas-object";
import FastVector from "fast-vector";
import { AnimatedValue } from "src/cores/AnimatedValue";
import { easeCubicInOut, easeCubicOut, easeSinInOut, easeSinOut } from 'd3-ease';

export interface AnimationBezierCurveLineOptions extends BezierCurveLineOptions {
  delay?: number
  factor?: number
}

export class AnimationBezierCurveLine extends BezierCurveLine {
  factor: number;
  animatedValues: Array<AnimatedValue>;
  alphaAnimatedValue: AnimatedValue;

  constructor({ positions, alpha, color, scale, lineCap, lineWidth, factor = 0.5, delay = 0 }: AnimationBezierCurveLineOptions) {
    super({ positions, alpha, color, scale, lineCap, lineWidth });

    this.factor = factor;
    this.animatedValues = [];
    this.alphaAnimatedValue = new AnimatedValue(0, 1, 300, delay, easeSinOut);

    for (let i = 0; i < positions.length; i++) {
      this.animatedValues.push(
        new AnimatedValue(0, 1, 300, i * 20 + delay, easeSinOut)
      );
    }
  }

  public update(delta: number) {
    this.alphaAnimatedValue.update(delta);

    for (let i = 0; i < this.animatedValues.length; i++) {
      this.animatedValues[i].update(delta);
    }
  }

  public render(context: CanvasRenderingContext2D) {
    let dx1 = 0;
    let dy1 = 0;
    let dx2 = 0;
    let dy2 = 0;
    let prevEndPosition: FastVector | null = null;

    context.save();
    context.beginPath();
    context.strokeStyle = this.color;
    context.globalAlpha = this.alpha * this.alphaAnimatedValue.value;
    context.lineCap = this.lineCap;
    context.lineWidth = this.lineWidth * this.scale;

    for (let i = 1, length = this.positions.length; i < length; i++) {
      const animatedValue = this.animatedValues[i].value;
      const prevPosition = this.positions[i - 1];
      const currentPosition = this.positions[i];
      const nextPosition = this.positions[i + 1];

      if (nextPosition) {
        dx2 = (nextPosition.x - currentPosition.x) * -this.factor;
        dy2 = (nextPosition.y - currentPosition.y) * -this.factor;
      } else {
        dx2 = 0;
        dy2 = 0;
      }

      const startPosition = prevEndPosition ? prevEndPosition : new FastVector(
        Math.round(prevPosition.x * this.scale) + 0.5,
        Math.round(prevPosition.y * this.scale) + 0.5,
      );

      const endPosition = new FastVector(
        Math.round(currentPosition.x * this.scale) + 0.5,
        Math.round(currentPosition.y * this.scale) + 0.5,
      );

      const cp1Position = new FastVector(
        Math.round((prevPosition.x - dx1) * this.scale) + 0.5,
        Math.round((prevPosition.y - dy1) * this.scale) + 0.5
      );

      const cp2Position = new FastVector(
        Math.round((currentPosition.x + dx2) * this.scale) + 0.5,
        Math.round((currentPosition.y + dy2) * this.scale) + 0.5
      );

      const animatedEndPosition = FastVector.lerp(startPosition, endPosition, animatedValue);
      const animatedCp1Position = FastVector.lerp(startPosition, cp1Position, animatedValue);
      const animatedCp2Position = FastVector.lerp(startPosition, cp2Position, animatedValue);
      const animatedPosition = FastVector.lerp(startPosition, endPosition, animatedValue);

      context.moveTo(startPosition.x, startPosition.y)
      context.bezierCurveTo(
        animatedCp1Position.x,
        animatedCp1Position.y,
        animatedCp2Position.x,
        animatedCp2Position.y,
        animatedEndPosition.x,
        animatedEndPosition.y
      );

      dx1 = dx2;
      dy1 = dy2;
      prevEndPosition = animatedPosition;
    }

    context.stroke();
    context.restore();
  }
}

interface AdjointLineOptions extends LineOptions {
  delay?: number;
}

export class AdjointLine extends Line {
  animatedValue: AnimatedValue;

  constructor({
    position,
    endPosition,
    segments,
    alpha,
    color,
    scale,
    lineCap,
    lineWidth,
    rotation,
    delay = 0,
  }: AdjointLineOptions) {
    super({
      position,
      endPosition,
      segments,
      alpha,
      color,
      scale,
      lineCap,
      lineWidth,
      rotation,
    });

    this.endPosition = endPosition;
    this.lineCap = lineCap || "butt";
    this.lineWidth = lineWidth || 1;
    this.segments = segments;
    this.animatedValue = new AnimatedValue(0, 1, 1000, delay, easeCubicInOut);
  }

  update(delta: number) {
    this.animatedValue.update(delta);
  }

  render(context: CanvasRenderingContext2D) {
    context.save();
    context.beginPath();
    context.translate(
      this.position.x * this.scale,
      this.position.y * this.scale
    );
    context.rotate(this.rotation);
    context.translate(
      -this.position.x * this.scale,
      -this.position.y * this.scale
    );
    if (this.segments) {
      context.setLineDash(this.segments);
    }
    context.strokeStyle = this.color;
    context.globalAlpha = this.alpha;
    context.lineCap = this.lineCap;
    context.lineWidth = this.lineWidth * this.scale;

    const moveToPosition = new FastVector(
      Math.round(this.position.x * this.scale) + 0.5,
      Math.round(this.position.y * this.scale) + 0.5
    );
    const lineToPosition = new FastVector(
      Math.round(this.endPosition.x * this.scale) + 0.5,
      Math.round(this.endPosition.y * this.scale) + 0.5
    );

    const endPosition = FastVector.lerp(
      moveToPosition,
      lineToPosition,
      this.animatedValue.value
    );

    context.moveTo(moveToPosition.x, moveToPosition.y);
    context.lineTo(endPosition.x, endPosition.y);

    context.stroke();
    context.restore();
  }
}

export interface AnimationTextOptions extends TextOptions {
  delay?: number;
}

export class AnimationText extends Text {
  animatedValue: AnimatedValue;

  constructor({
    position,
    alpha,
    color,
    content,
    fontFamily,
    fontWeight,
    fontSize,
    textAlign,
    textBaseline,
    scale,
    delay = 0,
  }: AnimationTextOptions) {
    super({
      position,
      alpha,
      color,
      content,
      fontFamily,
      fontWeight,
      fontSize,
      textAlign,
      textBaseline,
      scale,
    });

    this.animatedValue = new AnimatedValue(0, 1, 1000, delay, easeCubicInOut);
  }

  update(delta: number) {
    this.animatedValue.update(delta);
  }

  render(context: CanvasRenderingContext2D) {
    context.save();
    context.beginPath();
    context.fillStyle = this.color;
    context.globalAlpha = this.animatedValue.value;
    context.textAlign = this.textAlign;
    context.textBaseline = this.textBaseline;
    context.font = `${this.fontWeight} ${this.fontSize * this.scale}px ${
      this.fontFamily
    }`;
    context.fillText(
      this.content,
      this.position.x * this.scale,
      this.position.y * this.scale
    );
    context.stroke();
    context.restore();
  }
}

export interface AnimationCircleOptions extends CircleOptions {
  delay?: number;
}

export class AnimationCircle extends Circle {
  animatedValue: AnimatedValue;

  constructor({ position, radius, alpha, color, startAngle, endAngle, scale, delay }: AnimationCircleOptions) {
    super({ position, radius, alpha, color, startAngle, endAngle, scale });

    this.animatedValue = new AnimatedValue(0, 1, 600, delay, easeCubicOut);
  }

  public update(delta: number) {
    this.animatedValue.update(delta);
  }

  public render(context: CanvasRenderingContext2D) {
    const radius = this.animatedValue.value * (this.radius * this.scale);

    context.save();
    context.beginPath();
    context.fillStyle = this.color;
    context.globalAlpha = this.alpha;
    context.arc(this.position.x * this.scale, this.position.y * this.scale, radius, this.startAngle, this.endAngle);
    context.fill();
    context.restore();
  }
}
