import axios from 'axios';
import { all, take } from 'redux-saga/effects';
import { createAsyncSaga } from '../cores/createAsyncSaga';
import { UserActions, UserType } from '../store/user';
import { get } from 'lodash';

export default function*() {
  yield all([...createAsyncSaga(UserActions), login(), logout()]);
}

function* login() {
  while (true) {
    const response = yield take(UserActions.login.success);
    const token = get(response, ['payload', 'data', 'token']);

    if (token) {
      axios.defaults.headers['authorization'] = `JWT ${token}`;
      localStorage.setItem('token', token);
    }
  }
}

function* logout() {
  while (true) {
    yield take(UserType.LOGOUT);
    delete axios.defaults.headers['authorization'];
    localStorage.removeItem('token');
  }
}
