import produce from 'immer';
import { handleActions, Action } from 'redux-actions';
import { action } from 'typesafe-actions';

export enum LoadingType {
  SET_ASYNC = '@loading/SET_ASYNC'
}

export interface LoadingState {
  asyncMap: { [key: string]: number };
}

export const LoadingActions = {
  setAsync: (key: string, value: boolean) => action(LoadingType.SET_ASYNC, { key, value })
};

const initialState: LoadingState = {
  asyncMap: {}
};

export default handleActions<LoadingState, any>(
  {
    [LoadingType.SET_ASYNC]: (state, action: Action<{ key: string; value: boolean }>) => {
      return produce(state, draft => {
        const { key, value } = action.payload;
        if (draft.asyncMap[key] === undefined) {
          draft.asyncMap[key] = 0;
        }

        if (value) {
          draft.asyncMap[key]++;
        } else if (draft.asyncMap[key] > 0) {
          draft.asyncMap[key]--;
        }
      });
    }
  },
  initialState
);
