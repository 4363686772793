import './styles/index.module.scss';
import 'intersection-observer';
import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import createStore from 'src/cores/createStore';
import { defaultToken } from 'src/cores/defaultToken';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'src/store';
import App from 'src/components/App/App';
import * as serviceWorker from 'src/serviceWorker';

const store = createStore();

axios.defaults.timeout = 300000
axios.defaults.baseURL = process.env.REACT_APP_API;
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.withCredentials = true;

if (defaultToken) {
  axios.defaults.headers = {
    authorization: `JWT ${defaultToken}`
  };
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('app')
);

serviceWorker.unregister();
