import { getDevicePixelRatio } from "src/cores/getDevicePixelRatio";
import { PriceDistributionData } from "src/declaration/PriceDistributionData";
import { Rect } from "src/internal/Rect";
import { Graphics } from "src/internal/Graphics";
import { get, chain } from "lodash";
import {
  AdjointLine,
  AnimationText,
  AnimationBezierCurveLine,
  AnimationCircle,
} from "src/cores/graphics";
import FastVector from "fast-vector";
import { toLocaleString } from "src/cores/toLocaleString";
import { Line, Rectangle } from "canvas-object";

// @ts-ignore
import PolynomialRegression from "js-polynomial-regression";
import { median } from "simple-statistics";

const adjointLineMarginTop = 22;
const adjointLineMarginLeft = 80;
const adjointLineMarginRight = 20;
const adjointLineMarginBottom = 20;

const PI = Math.PI;
const currentYear = new Date().getFullYear();

class Polygon {
  points: Array<FastVector> = [];
  constructor(points: Array<FastVector>) {
    this.points = points;
  }
}

function isInside(vector: FastVector, polygon: Polygon) {
  let crosses = 0;
  const { points } = polygon;
  const pointLength = points.length;
  for (let i = 0; i < pointLength; i++) {
    const j = (i + 1) % pointLength;
    if (points[i].y > vector.y !== points[j].y > vector.y) {
      const atX =
        ((points[j].x - points[i].x) * (vector.y - points[i].y)) /
          (points[j].y - points[i].y) +
        points[i].x;
      if (vector.x < atX) {
        crosses++;
      }
    }
  }
  return crosses % 2 > 0;
}

export class PriceDistribution {
  ref: HTMLElement;
  data: PriceDistributionData["a"];
  canvas: HTMLCanvasElement;
  canvasSize: Rect;
  context: CanvasRenderingContext2D;
  resolution: number;
  intersectionObserver: IntersectionObserver;

  layers: Array<Graphics> = [];
  animationFrameId: number;
  delta: number = 0;
  startedAt: number = 0;
  category: number = 0;
  isEnabled = false;
  callback: (pd: PriceDistribution) => void;

  group: string | null = null;
  dataMin: number | null = null;
  dataMax: number | null = null;
  mileageMin: number | null = null;
  mileageMax: number | null = null;
  xType: number | null = null;
  type: string | null = null;
  isWave: boolean | null = null;

  constructor(
    ref: HTMLElement,
    data: PriceDistributionData["a"],
    category: number,
    callback: (pd: PriceDistribution) => void
  ) {
    this.callback = callback;
    this.resolution = getDevicePixelRatio();
    this.category = category;

    this.ref = ref;
    this.data = data;
    this.canvas = document.createElement("canvas");
    this.canvasSize = new Rect(window.innerWidth, 238);
    this.context = this.canvas.getContext("2d")!;
    this.ref.appendChild(this.canvas);
    this.intersectionObserver = new IntersectionObserver(
      this.handleIntersection,
      { root: null, rootMargin: "0px", threshold: 0.5 }
    );

    this.intersectionObserver.observe(this.canvas);

    this.layers = [
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context }),
      new Graphics({ context: this.context }),
    ];

    this.handleResize(null);
    this.initializeGraphics();
    this.animationFrameId = window.requestAnimationFrame(this.handleEnterFrame);
    window.addEventListener("resize", this.handleResize);
  }

  handleIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) => {
    this.isEnabled = entries[0].isIntersecting;
  };

  handleResize = (e: Event | null) => {
    this.canvasSize.width = window.innerWidth;
    this.canvas.setAttribute(
      "width",
      (this.canvasSize.width * this.resolution).toString()
    );
    this.canvas.setAttribute(
      "height",
      (this.canvasSize.height * this.resolution).toString()
    );
    this.canvas.style.width = `${this.canvasSize.width}px`;
    this.canvas.style.height = `${this.canvasSize.height}px`;
  };

  handleEnterFrame = (tick: number) => {
    this.animationFrameId = window.requestAnimationFrame(this.handleEnterFrame);

    if (this.startedAt != tick) {
      this.delta = (tick - this.startedAt) * 0.001;
      this.startedAt = tick;
    }

    if (!this.isEnabled) {
      return;
    }

    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    for (let i = 0, length = this.layers.length; i < length; i++) {
      this.layers[i].render();
      this.layers[i].update(this.delta);
    }
  };

  initializeGraphics() {
    const cars: Array<[string, number, string, number, number, boolean]> = [];
    const group = get(this.data, "group");

    if (typeof group === "string") {
      this.group = group.toUpperCase();
    }

    for (
      let i = 0, length = get(this.data, "is_highlight").length;
      i < length;
      i++
    ) {
      cars.push([
        "",
        this.data.price[i],
        "",
        this.data.mileage[i],
        this.data.year[i],
        this.data.is_highlight[i],
      ]);
    }

    let xType: number | null = null;
    const isTypeAA =
      chain(cars)
        .filter((car) => car[3] <= 25000)
        .size()
        .value() /
        cars.length >=
      0.9;
    const isTypeCC =
      currentYear -
        chain(cars)
          .meanBy((car) => car[4])
          .value() >
      15;

    if (isTypeAA) {
      this.type = "AA";
    } else if (isTypeCC) {
      this.type = "CC";
    }

    let minMileage = 0;
    let maxMileage = 200000;

    let verticalPriceRange: number | null = null;
    let verticalLineCount: number | null = null;

    let xDotdashedLine = false;
    let yDotdashedLine = false;
    let yStartPrice = 0;

    const dataMax = chain(cars)
      .map((c) => c[1])
      .max()
      .value();

    const dataMin = chain(cars)
      .map((c) => c[1])
      .min()
      .value();

    const highlightDataMin = chain(cars)
      .filter((c) => c[5])
      .map((c) => c[1])
      .min()
      .value();

    const highlightDataMax = chain(cars)
      .filter((c) => c[5])
      .map((c) => c[1])
      .max()
      .value();

    if (!dataMax || !dataMin) {
      return;
    }

    this.mileageMax = chain(cars)
      .map((c) => c[3])
      .max()
      .value();

    this.mileageMin = chain(cars)
      .map((c) => c[3])
      .min()
      .value();

    this.dataMin = dataMin;
    this.dataMax = dataMax;

    for (let i = 1; i <= 3; i++) {
      const nextMileage = 200000 - i * 50000;

      if (
        chain(cars)
          .filter((c) => c[3] >= nextMileage)
          .size()
          .value() <= 0
      ) {
        xType = 0;
        maxMileage = nextMileage;
      }
    }

    if (minMileage <= 0 && maxMileage >= 200000) {
      if (
        chain(cars)
          .filter((c) => c[3] <= 100000)
          .size()
          .value() <= 0
      ) {
        xType = 2;
        minMileage = 100000;
        maxMileage = 200000;
        xDotdashedLine = true;
      }

      if (
        chain(cars)
          .filter((c) => c[3] <= 150000)
          .size()
          .value() <= 0
      ) {
        xType = 2;
        minMileage = 150000;
        maxMileage = 250000;
        xDotdashedLine = true;
      }
    }

    if (xType === null) {
      xType = 1;

      if (
        chain(cars)
          .filter((c) => c[3] <= 100000)
          .size()
          .value() <= 0
      ) {
        minMileage = 100000;
        xDotdashedLine = true;
      }

      if (
        chain(cars)
          .filter((c) => c[3] >= 150000)
          .size()
          .value() <= 0
      ) {
        maxMileage = 150000;
      }
    }

    this.xType = xType;

    if (dataMin < 200) {
      if (dataMax <= 100) {
        verticalLineCount = 5;
        verticalPriceRange = 20;
      } else if (dataMax > 100 && dataMax <= 150) {
        verticalLineCount = 3;
        verticalPriceRange = 50;
      } else if (dataMax > 150 && dataMax <= 200) {
        verticalLineCount = 4;
        verticalPriceRange = 50;
      } else if (dataMax > 200 && dataMax <= 250) {
        verticalLineCount = 5;
        verticalPriceRange = 50;
      } else if (dataMax > 250 && dataMax <= 300) {
        verticalLineCount = 3;
        verticalPriceRange = 100;
      } else if (dataMax > 300 && dataMax <= 400) {
        verticalLineCount = 4;
        verticalPriceRange = 100;
      } else if (dataMax > 400 && dataMax <= 500) {
        verticalLineCount = 5;
        verticalPriceRange = 100;
      } else if (dataMax > 500 && dataMax <= 600) {
        verticalLineCount = 3;
        verticalPriceRange = 200;
      } else if (dataMax > 600 && dataMax <= 800) {
        verticalLineCount = 4;
        verticalPriceRange = 200;
      } else if (dataMax > 800) {
        const roundDataMax = Math.round(dataMax / 100) * 100;
        verticalLineCount = 5;
        verticalPriceRange = Math.round(roundDataMax / 4 / 100) * 100;

        if (verticalPriceRange * 4 > dataMax) {
          verticalLineCount = 4;
        } else if (verticalPriceRange * 3 > dataMax) {
          verticalLineCount = 3;
        }
      }
    } else if (dataMin >= 200) {
      const roundDataMax = Math.round(dataMax / 100) * 100;
      const floorDataMin = Math.floor(dataMin / 100) * 100;
      const dataMid = roundDataMax - floorDataMin;
      const divideByThreeDataMid = dataMid / 3;

      yStartPrice = floorDataMin;
      yDotdashedLine = true;

      if (divideByThreeDataMid < 50) {
        verticalLineCount = 5;
        verticalPriceRange = 50;

        if (yStartPrice + verticalPriceRange * 4 >= dataMax) {
          verticalLineCount = 4;
        } else if (yStartPrice + verticalPriceRange * 3 >= dataMax) {
          verticalLineCount = 3;
        }
      } else {
        verticalLineCount = 5;
        verticalPriceRange = Math.ceil(divideByThreeDataMid / 100) * 100;

        if (yStartPrice + verticalPriceRange * 4 >= dataMax) {
          verticalLineCount = 4;
        } else if (yStartPrice + verticalPriceRange * 3 >= dataMax) {
          verticalLineCount = 3;
        }
      }
    }

    if (verticalLineCount === null || verticalPriceRange === null) {
      throw Error("일치하는 케이스가 없습니다.");
    }

    let xStartMileage = minMileage / 50000;
    let horizontalLineCount = (maxMileage - minMileage) / 50000;

    if (xDotdashedLine) {
      xStartMileage = xStartMileage - 1;
      horizontalLineCount = horizontalLineCount + 1;
    }

    xStartMileage = xStartMileage * 5;

    const mileageLineWidth =
      this.canvasSize.width - (adjointLineMarginLeft + adjointLineMarginRight);
    const mileageLineOneWidth = mileageLineWidth / horizontalLineCount;

    const adjointLineWidth = this.canvasSize.width - adjointLineMarginRight;
    const adjointLineHeight =
      this.canvasSize.height - adjointLineMarginTop - adjointLineMarginBottom;
    const adjointLineOneHeight = adjointLineHeight / verticalLineCount;
    const endAdjointLineHeight = adjointLineHeight + adjointLineMarginTop;

    for (let i = 0; i < horizontalLineCount + 1; i++) {
      const x = adjointLineMarginLeft + i * mileageLineOneWidth;

      this.layers[1].add(
        new AdjointLine({
          position: new FastVector(x, endAdjointLineHeight - 5),
          endPosition: new FastVector(x, endAdjointLineHeight),
          color: "#e9edf4",
          lineWidth: 1,
          scale: this.resolution,
          delay: i * 100,
        }),
        new AnimationText({
          position: new FastVector(x, endAdjointLineHeight + 5),
          textAlign: "center",
          textBaseline: "top",
          content: i === 0 ? "0" : `${i * 5 + xStartMileage}만km`,
          fontSize: 10,
          fontFamily: "Spoqa Han Sans",
          fontWeight: "normal",
          color: "#afc2db",
          delay: i * 100,
          scale: this.resolution,
        })
      );
    }

    for (let i = 0; i < verticalLineCount + 1; i++) {
      let price =
        yStartPrice +
        verticalPriceRange * (verticalLineCount - (yDotdashedLine ? 1 : 0) - i);

      if (i == verticalLineCount && yDotdashedLine) {
        price = 0;
      }

      const adjointLineY = adjointLineMarginTop + i * adjointLineOneHeight;

      this.layers[1].add(
        new AdjointLine({
          position: new FastVector(adjointLineMarginLeft, adjointLineY),
          endPosition: new FastVector(adjointLineWidth, adjointLineY),
          color: "#e9edf4",
          lineWidth: 1,
          scale: this.resolution,
          delay: i * 100,
        })
      );

      this.layers[2].add(
        new AnimationText({
          position: new FastVector(adjointLineMarginLeft - 8, adjointLineY),
          textAlign: "right",
          textBaseline: "middle",
          content: toLocaleString(price.toFixed(0)) + "만원",
          fontSize: 10,
          fontFamily: "Spoqa Han Sans",
          fontWeight: "normal",
          color: "#afc2db",
          delay: i * 100,
          scale: this.resolution,
        })
      );
    }

    if (yDotdashedLine) {
      const positions: Array<FastVector> = [];
      const dotdashedLineSpacing = this.canvasSize.width / 15;
      const adjointLineOneWidth = adjointLineWidth / dotdashedLineSpacing;

      for (let i = 0; ; i++) {
        let x = adjointLineOneWidth * i + adjointLineMarginLeft - 5;
        let y =
          adjointLineMarginTop +
          (verticalLineCount - 1 + 0.5) * adjointLineOneHeight;
        let isOverflow = x > adjointLineWidth;

        if (i % 2 === 0) {
          y += 2;
        } else {
          y -= 2;
        }

        positions.push(new FastVector(x, y));

        if (isOverflow) {
          break;
        }
      }

      if (yStartPrice !== verticalPriceRange) {
        this.layers[2].add(
          new AnimationBezierCurveLine({
            positions,
            color: "#e9edf4",
            lineWidth: 4,
            delay: 100,
            scale: this.resolution,
          }),
          new AnimationBezierCurveLine({
            positions,
            color: "#fff",
            lineWidth: 2,
            delay: 100,
            scale: this.resolution,
          })
        );
      }
    }

    if (xDotdashedLine) {
      const positions: Array<FastVector> = [];
      const dotdashedLineSpacing = this.canvasSize.height / 10;
      const mileageLineOneHeight = adjointLineHeight / dotdashedLineSpacing + 8;

      for (let i = 0; ; i++) {
        let x = adjointLineMarginLeft + mileageLineOneWidth * 0.5 - 4;
        let y = i * mileageLineOneHeight - 4;
        let isOverflow = y > adjointLineHeight + adjointLineMarginTop;

        if (i % 2 === 0) {
          x += 2;
        } else {
          x -= 2;
        }

        positions.unshift(new FastVector(x, y));

        if (isOverflow) {
          break;
        }
      }

      this.layers[2].add(
        new AnimationBezierCurveLine({
          positions,
          color: "#e9edf4",
          lineWidth: 4,
          delay: 100,
          scale: this.resolution,
        }),
        new AnimationBezierCurveLine({
          positions,
          color: "#fff",
          lineWidth: 2,
          delay: 100,
          scale: this.resolution,
        })
      );
    }

    const maxPrice =
      verticalPriceRange * (verticalLineCount - (yDotdashedLine ? 1 : 0)) +
      yStartPrice;

    const filteredData = [];

    const midPrice = maxPrice - yStartPrice;
    const midMileage = maxMileage - minMileage;

    const graphWidth =
      mileageLineOneWidth * horizontalLineCount -
      (xDotdashedLine ? mileageLineOneWidth : 0);
    const graphHeight =
      adjointLineOneHeight *
      (verticalLineCount -
        (yDotdashedLine && yStartPrice !== verticalPriceRange ? 1 : 0));

    const calcX = (mileage: number) => (mileage - minMileage) / midMileage;
    const calcY = (price: number) => 1 - (price - yStartPrice) / midPrice;

    const highlightCirclePositions: Array<FastVector> = [];

    for (let i = 0, length = cars.length; i < length; i++) {
      let [, price, , mileage, , isHighlight] = cars[i];
      if (
        (yDotdashedLine && price < yStartPrice) ||
        minMileage > mileage ||
        maxMileage < mileage
      ) {
        continue;
      }

      filteredData.push(cars[i]);

      let x = calcX(mileage) * graphWidth;

      if (xDotdashedLine) {
        x += mileageLineOneWidth;
      }

      const y = calcY(price) * graphHeight;

      const circlePosition = new FastVector(
        adjointLineMarginLeft + x,
        adjointLineMarginTop + y
      );

      if (isHighlight) {
        highlightCirclePositions.push(circlePosition);
      }

      const delay = (circlePosition.x / graphWidth) * 500 + 125;

      if (isHighlight) {
        this.layers[1].add(
          new AnimationCircle({
            position: circlePosition,
            radius: 3,
            color: "#b0c4fc",
            scale: this.resolution,
            delay,
          })
        );
      } else {
        this.layers[0].unshift(
          new AnimationCircle({
            position: circlePosition,
            radius: 15,
            color: "#e4e5e9",
            scale: this.resolution,
            delay,
          })
        );

        this.layers[0].add(
          new AnimationCircle({
            position: circlePosition,
            radius: 14,
            color: "#eff0f2",
            scale: this.resolution,
            delay,
          })
        );
      }
    }

    this.layers[1].add(
      new Rectangle({
        position: new FastVector(0, 0),
        width: adjointLineMarginLeft,
        height: adjointLineHeight + adjointLineMarginTop,
        color: "#fff",
        scale: this.resolution,
      }),
      new Rectangle({
        position: new FastVector(
          this.canvasSize.width - adjointLineMarginRight,
          0
        ),
        width: adjointLineMarginRight,
        height: adjointLineHeight + adjointLineMarginTop,
        color: "#fff",
        scale: this.resolution,
      })
    );

    this.callback.call(null, this);

    if (
      filteredData.length <= 1 ||
      isTypeAA ||
      isTypeCC ||
      this.group === "G"
    ) {
      return;
    }

    const model = PolynomialRegression.read(
      chain(filteredData)
        .map((i) => ({ x: i[3], y: i[1] }))
        .value(),
      3
    );

    const regressionData: Array<{
      price: number;
      mileage: number;
      position: FastVector;
    }> = [];

    const terms = model.getTerms();
    const mileageStandard = 5000;
    const filteredMaxMileage = chain(filteredData)
      .maxBy((i) => i[3])
      .get(3)
      .value();

    const filteredMinMileage = chain(filteredData)
      .minBy((i) => i[3])
      .get(3)
      .value();

    for (let i = minMileage; i <= maxMileage; i += mileageStandard) {
      if (i < filteredMinMileage || i > filteredMaxMileage) {
        continue;
      }

      let x = calcX(i) * graphWidth;

      if (xDotdashedLine) {
        x += mileageLineOneWidth;
      }

      let price = model.predictY(terms, i);
      let y = calcY(price) * graphHeight;

      const position = new FastVector(
        adjointLineMarginLeft + x,
        adjointLineMarginTop + y
      );

      regressionData.push({
        price,
        mileage: i,
        position,
      });
    }

    const suitabilityData: Array<{
      id: number;
      radian: number;
      mileage: number;
      position: FastVector;
      isPassed: boolean;
    }> = [];

    for (let i = 0; i < regressionData.length - 1; i++) {
      const { mileage, position, price } = regressionData[i];
      const { position: nextPosition } = regressionData[i + 1];

      const radian = position.angleBetween(nextPosition);
      const degree = (radian * 180) / Math.PI;
      const isPassed = degree >= 0 && degree <= 90;

      suitabilityData.push({
        id: i,
        radian,
        mileage,
        position: position.clone(),
        isPassed,
      });
    }

    suitabilityData.push({
      id: suitabilityData.length,
      radian: 0,
      mileage: regressionData[regressionData.length - 1].mileage,
      position: regressionData[regressionData.length - 1].position.clone(),
      isPassed: suitabilityData[suitabilityData.length - 1].isPassed,
    });

    let prevMaxY: number | null = null;

    for (let i = 0; i < suitabilityData.length; i++) {
      if (prevMaxY === null || prevMaxY < suitabilityData[i].position.y) {
        prevMaxY = suitabilityData[i].position.y;
      } else {
        suitabilityData[i].isPassed = false;
      }
    }

    const passedIndex = chain(suitabilityData)
      .findIndex((i) => i.isPassed)
      .value();
    const failedIndex = chain(suitabilityData)
      .findIndex((i) => !i.isPassed)
      .value();
    const lastPassedIndex = chain(suitabilityData)
      .findLastIndex((i) => i.isPassed)
      .value();
    const lastFailedIndex = chain(suitabilityData)
      .findLastIndex((i) => !i.isPassed)
      .value();

    if (passedIndex !== 0) {
      for (let i = passedIndex; i > failedIndex; i--) {
        suitabilityData[i - 1].radian = chain(suitabilityData)
          .slice(i, i + 6)
          .meanBy((i) => i.radian)
          .value();
      }
    }

    if (lastPassedIndex !== suitabilityData.length - 1) {
      for (let i = lastPassedIndex + 1; i < lastFailedIndex; i++) {
        suitabilityData[i].radian = chain(suitabilityData)
          .slice(i - 6, i)
          .meanBy((i) => i.radian)
          .value();
      }
    }

    if (passedIndex !== 0) {
      for (let i = passedIndex; i > failedIndex; i--) {
        const { position } = suitabilityData[i];
        const { radian } = suitabilityData[i - 1];

        const nextDirection = new FastVector(
          Math.cos(radian),
          Math.sin(radian)
        );
        const nextPosition = position.sub(nextDirection.mul(1000));

        const guideStartPosition = new FastVector(
          suitabilityData[i - 1].position.x,
          -1000
        );
        const guideEndPosition = new FastVector(
          suitabilityData[i - 1].position.x,
          1000
        );

        const intersection = FastVector.intersection(
          guideStartPosition,
          guideEndPosition,
          position,
          nextPosition
        );

        if (!intersection) {
          continue;
        }

        suitabilityData[i - 1].position = intersection;
      }

      suitabilityData[passedIndex].position = suitabilityData[
        passedIndex - 1
      ].position.lerp(suitabilityData[passedIndex + 1].position, 0.5);
    }

    if (lastPassedIndex !== suitabilityData.length - 1) {
      for (let i = lastPassedIndex; i < lastFailedIndex; i++) {
        const { position, radian } = suitabilityData[i];

        const nextDirection = new FastVector(
          Math.cos(radian),
          Math.sin(radian)
        );

        const nextPosition = position.add(nextDirection.mul(1000));

        const guideStartPosition = new FastVector(
          suitabilityData[i + 1].position.x,
          -1000
        );
        const guideEndPosition = new FastVector(
          suitabilityData[i + 1].position.x,
          1000
        );

        const intersection = FastVector.intersection(
          guideStartPosition,
          guideEndPosition,
          position,
          nextPosition
        );

        if (!intersection) {
          continue;
        }

        suitabilityData[i + 1].position = intersection;
      }

      if (lastPassedIndex + 2 <= suitabilityData.length - 1) {
        suitabilityData[lastPassedIndex + 1].position = suitabilityData[
          lastPassedIndex + 2
        ].position.lerp(suitabilityData[lastPassedIndex - 1].position, 0.5);
      }
    }

    const suitabilityPositions = chain(suitabilityData)
      .map((data) => data.position)
      .value();

    const suitabilityPolygon = new Polygon([
      ...suitabilityPositions,
      new FastVector(
        suitabilityPositions[suitabilityPositions.length - 1].x,
        0
      ),
      new FastVector(suitabilityPositions[0].x, 0),
    ]);

    const topCount = chain(highlightCirclePositions)
      .filter((p) => isInside(p, suitabilityPolygon))
      .size()
      .value();

    const bottomCount = highlightCirclePositions.length - topCount;

    const highlightMinY = chain(highlightCirclePositions)
      .minBy((p) => p.y)
      .get("y")
      .value();
    const highlightMaxY = chain(highlightCirclePositions)
      .maxBy((p) => p.y)
      .get("y")
      .value();

    const iteration = 20;
    let diffY = highlightMaxY - highlightMinY;

    if (topCount > bottomCount) {
      diffY *= -1;
    }

    const oneDiffY = diffY / iteration;
    let realDiff = diffY;
    let prevIterationDiffCount: number | null = null;

    for (let i = 0; i < iteration; i++) {
      const diff = oneDiffY * i;
      const positions = chain(suitabilityData)
        .map((data) => data.position.add(0, diff))
        .value();
      const polygon = new Polygon([
        ...positions,
        new FastVector(
          positions[positions.length - 1].x,
          0
        ),
        new FastVector(positions[0].x, 0),
      ])

      const topCount = chain(highlightCirclePositions)
        .filter((p) => isInside(p, polygon))
        .size()
        .value();

      const bottomCount = highlightCirclePositions.length - topCount;

      const iterationDiffCount = Math.abs(topCount - bottomCount);

      if (prevIterationDiffCount === null) {
        prevIterationDiffCount = iterationDiffCount;
      }

      if (prevIterationDiffCount !== null && prevIterationDiffCount > iterationDiffCount) {
        prevIterationDiffCount = iterationDiffCount;
      }

      if (prevIterationDiffCount < iterationDiffCount) {
        realDiff = oneDiffY * (i - 1);
        break;
      }
    }

    const lastSuitabilityPositions = chain(suitabilityData)
      .map((data) => data.position.add(0, realDiff))
      .value();

    const isStartPriceChecking = yDotdashedLine && yStartPrice !== verticalPriceRange;
    const minPrice = isStartPriceChecking ? yStartPrice : 40;

    for (let i = 0; i < lastSuitabilityPositions.length; i++) {
      const { y } = lastSuitabilityPositions[i];
      let currentPrice = ((1 - ((y - adjointLineMarginTop) / graphHeight)) * midPrice);
      if (isStartPriceChecking) {
        currentPrice += yStartPrice;
      }

      if (currentPrice < minPrice) {
        lastSuitabilityPositions.splice(i, lastSuitabilityPositions.length - i);
        break;
      }
    }

    this.layers[2].add(
      new AnimationBezierCurveLine({
        positions: chain(regressionData)
          .map((data) => data.position)
          .value(),
        color: "#e74c3c",
        lineWidth: 4,
        lineCap: "round",
        delay: 400,
        scale: this.resolution,
        factor: 0,
      })
    );

    this.layers[2].add(
      new AnimationBezierCurveLine({
        positions: lastSuitabilityPositions,
        color: "#3498db",
        lineWidth: 4,
        lineCap: "round",
        delay: 400,
        scale: this.resolution,
        factor: 0,
      })
    );
  }

  destroy() {
    if (this.animationFrameId) {
      window.cancelAnimationFrame(this.animationFrameId);
      window.removeEventListener("resize", this.handleResize);

      this.ref.removeChild(this.canvas);
      this.intersectionObserver.unobserve(this.canvas);
    }
  }
}
