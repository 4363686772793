import { DisplayObject } from 'canvas-object';

interface GraphicsOptions {
  context: CanvasRenderingContext2D;
}

export class Graphics {
  context: CanvasRenderingContext2D;
  displayObjects: Array<DisplayObject> = [];

  constructor({ context }: GraphicsOptions) {
    this.context = context;
  }

  add(...child: Array<DisplayObject>) {
    this.displayObjects.push(...child);
  }

  unshift(...child: Array<DisplayObject>) {
    this.displayObjects.unshift(...child);
  }

  render() {
    for (let i = 0, length = this.displayObjects.length; i < length; i++) {
      this.displayObjects[i].render(this.context);
    }
  }

  update(delta: number) {
    for (let i = 0, length = this.displayObjects.length; i < length; i++) {
      this.displayObjects[i].update(delta);
    }
  }
}
