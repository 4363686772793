import { all, call, put, take, takeEvery } from 'redux-saga/effects';
import { Action } from 'redux';
import { LoadingActions } from '../store/loading';
import { UserActions, UserType } from '../store/user';
import { get } from 'lodash';

function getPureType(type: string) {
  type = type.replace('/INITIAL', '').replace('/SUCCESS', '').replace('/FAILURE', '').replace('/CANCEL', '');
  return type;
}

export default function* () {
  yield all([watchOpenAsyncAction(), watchCloseAsyncAction(), watchFinalAsyncActions()]);
}

function* setLoading(type: string) {
  yield put(LoadingActions.setAsync(type, true));
}

function* watchOpenAsyncAction() {
  yield takeEvery('*', function* (action: Action) {
    if (/\/INITIAL$/g.test(action.type)) {
      const type = getPureType(action.type);
      yield call(setLoading, type);
    }
  });
}

function* watchCloseAsyncAction() {
  while (true) {
    const action = yield take((action: Action) => {
      return /\/SUCCESS$/g.test(action.type) || /\/FAILURE$/g.test(action.type) || /\/CANCEL$/g.test(action.type);
    });

    const type = getPureType(action.type);

    yield put(LoadingActions.setAsync(type, false));
  }
}

function* watchFinalAsyncActions() {
  while (true) {
    const action = yield take((action: Action) => {
      return /\/SUCCESS$/g.test(action.type) || /\/FAILURE$/g.test(action.type) || /\/CANCEL$/g.test(action.type);
    });

    if (action.type.indexOf(UserType.LOGIN) === -1 && get(action, 'payload.response.status') === 401) {
      yield put(UserActions.logout());
    }

    const type = getPureType(action.type);

    yield put(LoadingActions.setAsync(type, false));
  }
}
