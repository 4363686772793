import axios, { AxiosResponse } from "axios";
import produce from "immer";
import { handleActions } from "redux-actions";
import { PayloadAction } from "typesafe-actions";
import { PriceDistributionData } from "src/declaration/PriceDistributionData";
import { createAsyncSagaReducerMap } from "src/cores/createAsyncSagaReducerMap";
import createAsyncSagaAction from "src/cores/createAsyncSagaAction";

export enum CarsType {
  GET_PRICE_DISTRIBUTION_DATA = "@cars/GET_PRICE_DISTRIBUTION_DATA",
}

export interface CarsState {
  priceDistributionData: Array<PriceDistributionData>;
}

export const CarsActions = {
  getPriceDistributionData: createAsyncSagaAction(
    CarsType.GET_PRICE_DISTRIBUTION_DATA,
    (params: { date?: string, car_id?: string; }) => {
      return axios.get("/v3/customers/cars_price/", { params });
    }
  ),
};

const initialState: CarsState = {
  priceDistributionData: [],
};

export default handleActions<CarsState, any>(
  {
    ...createAsyncSagaReducerMap(CarsType.GET_PRICE_DISTRIBUTION_DATA, {
      onSuccess: (
        state,
        action: PayloadAction<
          string,
          AxiosResponse<Array<PriceDistributionData>>
        >
      ) => {
        return produce(state, (draft) => {
          draft.priceDistributionData = action.payload.data;
        });
      },
    }),
  },
  initialState
);
