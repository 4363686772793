import React, { FC, useCallback } from "react";
import styles from "./LoginForm.module.scss";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { UserActions } from 'src/store/user';
import Button from 'src/components/Button/Button';
import TextInput from 'src/components/TextInput/TextInput';

interface Props {}

interface LoginFormValues {
  username: string;
  password: string;
}

const LoginForm: FC<Props> = () => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    ({ username, password }: LoginFormValues) => {
      if (username === "") {
        return;
      }

      if (password === "") {
        return;
      }

      dispatch(UserActions.login(username, password));
    },
    [dispatch]
  );

  const { values, handleSubmit, handleChange } = useFormik<LoginFormValues>({
    initialValues: {
      username: "",
      password: "",
    },
    onSubmit,
  });

  return (
    <div className={styles.loginForm}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <h1 className={styles.title}>로그인</h1>
        <TextInput
          className={styles.input}
          placeholder="계정"
          name="username"
          value={values.username}
          onChange={handleChange}
        />
        <TextInput
          className={styles.input}
          placeholder="비밀번호"
          type="password"
          name="password"
          value={values.password}
          onChange={handleChange}
        />
        <div className={styles.actions}>
          <Button className={styles.loginButton} type="submit">
            로그인
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
