import { all } from 'redux-saga/effects';
import user from './user';
import cars from './cars';
import loading from './loading';

export default function* rootSaga() {
  yield all([
    user(),
    cars(),
    loading(),
  ]);
}
