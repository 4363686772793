import React, { FC, memo } from "react";
import styles from "./App.module.scss";
import { useTypedSelector } from "src/hooks/useTypedSelector";
import { Route, Switch } from "react-router-dom";
import LoginForm from "src/components/LoginForm/LoginForm";
import Home from 'src/components/Home/Home';

interface Props {}

const App: FC<Props> = memo(() => {
  const { token } = useTypedSelector(({ user: { token } }) => ({ token }));
  const isAuthenticated = token !== null;

  return (
    <div className={styles.app}>
      {isAuthenticated ? (
        <Switch>
          <Route exact path="/" component={Home} />
        </Switch>
      ) : (
        <LoginForm />
      )}
    </div>
  );
});

export default App;
