import React, { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';
import classNames from 'classnames';
import Ink from 'react-ink';

interface Props {
  className?: string;
  variant?: 'primary' | 'danger';
}

const Button: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  className,
  variant = 'primary',
  children,
  ...otherProps
}) => {
  return (
    <button className={classNames(styles.button, styles[variant], className)} {...otherProps}>
      {children}
      <Ink />
    </button>
  );
};

export default Button;
